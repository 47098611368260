<template>
    <v-container fluid grid-list-sm>
      <v-row no-gutters align="center" justify="center" column>
        <div>
          <div style="max-width: 800px;" class="mb-4">
            <v-row no-gutters align="start">
            <span>
              <v-icon
                v-if="$vuetify.breakpoint.smAndUp"
                size="56px"
                color="primary"
                class="pr-4">
                mdi-account
              </v-icon>
            </span>
              <div>
                <h3 class="text-uppercase">Редагування даних користувача</h3>
                <p v-if="$vuetify.breakpoint.smAndUp" class="mb-1">Ви зможете відредагувати персональні дані, змінити пароль та налаштування нагадування</p>
              </div>
            </v-row>
          </div>
          <div>
            <v-card flat :height="$vuetify.breakpoint.mdAndUp ? '630px' : '720px' " max-width="800px" :width="$vuetify.breakpoint.mdAndUp ? '800px' : '' ">
            <v-tabs
              v-model="tab"
              background-color="transparent"
              color="success darken-2"
            >
              <!--ПОШУК ПО ОСОБОВОМУ РАХУНКУ-->
              <v-tab>Персональна інформація</v-tab>
              <!--ПОШУК ПО АДРЕСІ-->
              <v-tab>Безпека</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" class=" px-4">
              <!--ПОШУК ПО ОСОБОВОМУ РАХУНКУ-->
              <v-tab-item>
                <v-form ref="personInfoForm" v-model="validPersonInfo">
                  <v-row class="pt-4" :class="`${$vuetify.breakpoint.smAndUp ? 'px-4' : ''}`">
                    <v-col cols="12" class="py-0" md="12" xs="12">
                      <v-text-field
                        label="E-mail"
                        name="email"
                        prepend-icon=""
                        type="email"
                        v-model="email"
                        :rules="[rules.required, rules.email]"
                        ref="email_ref"
                        validate-on-blur
                        outlined
                        :tabindex="tab === 0 ? '1' : ''"
                      />
                    </v-col>
                    <v-col cols="12" class="py-0" md="12" xs="12">
                      <v-text-field
                        label="Прізвище"
                        name="lastName"
                        prepend-icon=""
                        type="text"
                        v-model="lastName"
                        :rules="[rules.required, rules.valueLength(lastName, 'Прізвище', 2)]"
                        validate-on-blur
                        outlined
                        :tabindex="tab === 0 ? '2' : ''"
                      />
                    </v-col>
                    <v-col cols="12" class="py-0" md="12" xs="12">
                      <v-text-field
                        label="Ім’я"
                        name="firstName"
                        prepend-icon=""
                        type="text"
                        v-model="firstName"
                        :rules="[rules.required, rules.valueLength(firstName, 'Ім’я', 2)]"
                        validate-on-blur
                        outlined
                        :tabindex="tab === 0 ? '3' : ''"
                      />
                    </v-col>
                    <v-col cols="12" class="py-0" md="12" xs="12">
                      <v-text-field
                        label="По батькові"
                        name="middleName"
                        prepend-icon=""
                        type="text"
                        v-model="middleName"
                        :rules="[rules.required, rules.valueLength(middleName, 'По батькові', 2)]"
                        validate-on-blur
                        outlined
                        :tabindex="tab === 0 ? '4' : ''"
                      />
                    </v-col>
                    <v-col cols="6" class="py-0" md="6" xs="12">
                      <v-menu
                        ref="birthDateMenu"
                        v-model="birthDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        full-width
                        min-width="290px"
                        :tabindex="tab === 0 ? '5' : ''"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            label="Дата народження"
                            name="birthDate"
                            prepend-icon=""
                            type="text"
                            :value="birthDateFormatted"
                            :rules="[rules.required]"
                            readonly
                            v-on="on"
                            outlined
                          />
                        </template>
                        <v-date-picker
                          ref="birthDatePicker"
                          v-model="birthDate"
                          :max="new Date().toISOString().substr(0, 10)"
                          min="1930-01-01"
                          @change="saveBirthDate"
                        />
                      </v-menu>
                    </v-col>
                    <v-col cols="6" class="py-0" md="6">
                      <v-select
                        :items="sexItems"
                        prepend-icon=""
                        v-model="sex"
                        :rules="[rules.required]"
                        label="Стать"
                        outlined
                        :tabindex="tab === 0 ? '6' : ''"
                     />
                    </v-col>
                    <v-col cols="12" class="py-0" md="12" xs="12">
                      <v-switch
                        v-model="reminder"
                        label="Нагадувати про необхідність оплати"
                        color="success"
                        class="ma-0 pa-0"
                      />
                    </v-col>
                    <v-col cols="12" md="12" xs="12" class="text-md-end text-xs-end py-0">
                      <v-btn
                        class="success"
                        :disabled="!validPersonInfo"
                        @click="submitPersonInfo"
                      >
                        Оновити дані
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-tab-item>
              <!--ПОШУК ПО АДРЕСІ-->
              <v-tab-item>
                <v-form ref="securityForm" v-model="validSecurity">
                  <v-col cols="12" class="pt-4 py-0" md="12" xs="12" :class="`${$vuetify.breakpoint.smAndUp ? 'px-4' : ''}`">
                    <v-text-field
                      id="password"
                      label="Новий пароль"
                      name="password"
                      prepend-icon=""
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      v-model="password"
                      :rules="[rules.required, rules.valueLength(password, 'Пароль', 6)]"
                      validate-on-blur
                      @click:append="showPassword = !showPassword"
                      outlined
                      :tabindex="tab === 1 ? '1' : ''"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0" md="12" xs="12" :class="`${$vuetify.breakpoint.smAndUp ? 'px-4' : ''}`">
                    <v-text-field
                      id="confirmPassword"
                      label="Новий пароль (ще раз)"
                      name="confirmPassword"
                      prepend-icon=""
                      :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showConfirmPassword ? 'text' : 'password'"
                      v-model="confirmPassword"
                      :rules="[rules.required, rules.equal(confirmPassword, 'Паролі не співпадають', password)]"
                      @click:append="showConfirmPassword = !showConfirmPassword"
                      outlined
                      :tabindex="tab === 1 ? '2' : ''"
                    />
                  </v-col>
                  <v-col cols="12" md="12" xs="12" class="text-md-end text-xs-end py-0">
                    <v-btn class="success" :disabled="!validSecurity" @click="submitPassword">Змінити пароль</v-btn>
                  </v-col>
                </v-form>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
          </div>
        </div>
      </v-row>
    </v-container>
</template>
<script>
  import moment from 'moment'
  import UserAPI from '@/api/user'
  import {AUTH_LOGOUT} from '@/store/modules/authentication/mutation-types';
  import {VALIDATION_RULES} from '@/utils'

  export default {
    name: "profile",
    data() {
      return {
        email: '',
        password: '',
        showPassword: false,
        reminder: false,
        confirmPassword: '',
        showConfirmPassword: false,
        firstName: '',
        lastName: '',
        sex: null,
        sexItems: [
            {text: 'Чоловіча', value: 'male'},
            {text: 'Жіноча', value: 'female'},
        ],
        middleName: '',
        birthDate: null,
        birthDateMenu: false,
        validPersonInfo: false,
        validSecurity: false,
        tab: null,
      }
    },
    methods: {
      async updateUserData(password = false) {
        try {
          let user = {};

          if (password) {
            user = {
              password: this.password,
            }
          } else {
            user = {
              last_name: this.lastName,
              first_name: this.firstName,
              middle_name: this.middleName,
              email: this.email,
              sex: this.sex,
              get_reminder: this.reminder,
              birthday: this.birthday,
            };
          }

          await UserAPI.update(user);
        } catch (e) {
            this.$snackbar("Помилка оновлення даних користувача", 'error')
        }
      },
      saveBirthDate (date) {
        this.$refs.birthDateMenu.save(date)
      },
      async getUserData() {
          try {
            let {data} = await UserAPI.view();
            this.email = data.email;
            this.firstName = data.first_name;
            this.lastName = data.last_name;
            this.middleName = data.middle_name;
            this.reminder = data.get_reminders;
            this.sex = data.sex;
            this.birthDate = data.birthday;
          } catch (e) {
              this.$snackbar("Помилка одержання даних користувача", 'error')
          }
      },
      submitPersonInfo() {
        if (this.$refs.personInfoForm.validate()) {
          this.updateUserData().then(() => {
              this.$snackbar("Ваші дані оновлено успішно", 'success')
          });
        }
      },
      submitPassword() {
          if (this.$refs.securityForm.validate()) {
              this.updateUserData(true).then(() => {
                  this.$store.dispatch(AUTH_LOGOUT)
                      .then(() => {
                          this.$router.push('/login');
                          this.$snackbar("Пароль оновлено успішно", 'success')
                      });
              });
          }
      },
      logout() {
          this.$store.dispatch(AUTH_LOGOUT)
              .then(() => {
                  this.$router.push('/login');
              });
      }
    },
    computed: {
      rules() {
          return VALIDATION_RULES;
      },
      birthDateFormatted () {
        return this.birthDate ? moment(this.birthDate).format('DD.MM.YYYY') : '';
      }
    },
    watch: {
      birthDateMenu (val) {
        val && setTimeout(() => (this.$refs.birthDatePicker.activePicker = 'YEAR'))
      },
    },
    mounted() {
      this.getUserData().then(() => {
          this.$refs.personInfoForm.validate()
      });
    }
  }
</script>

<style scoped>

</style>
